var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-container" }, [
    _c(
      "div",
      { staticClass: "table-container" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("品类销售数据分析")]),
        _c(
          "vxe-table",
          { attrs: { data: _vm.list } },
          [
            _c("vxe-table-column", {
              attrs: {
                title: "品类",
                field: "name",
                "min-width": "100",
                "show-overflow": "",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "实际-销售额",
                field: "actualSales",
                "min-width": "100",
                "show-overflow": "",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "实际-销量-吨",
                field: "actualSold",
                "min-width": "100",
                "show-overflow": "",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "实际-成本",
                field: "actualCost",
                "min-width": "100",
                "show-overflow": "",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "实际-费用",
                field: "actualFee",
                "min-width": "100",
                "show-overflow": "",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "去年-销售额",
                field: "lastYearSales",
                "min-width": "100",
                "show-overflow": "",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "环比增长",
                field: "grow",
                "min-width": "100",
                "show-overflow": "",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "计划达成度",
                field: "reachDegree",
                "min-width": "100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("el-progress", {
                        attrs: {
                          "text-inside": true,
                          "stroke-width": 14,
                          percentage: row.reachDegree,
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "line-pie-container" },
      [_c("EchartsLinePie", { attrs: { title: "品类销售额" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "line-pie-container" },
      [_c("EchartsLinePie", { attrs: { title: "品类费用" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }