<template>
  <div id="linePie" ref="linePie"></div>
</template>
<script>
import echarts from 'echarts';

export default {
  name: 'echarts_line_pie',
  props: {
    echartsData: Array,
    title: String,
  },
  data() {
    return {
      // 图表配置信息
      options: {},
    };
  },
  methods: {
    getOptions() {
      const options = {
        title: {
          text: this.title,
          top: 20,
          left: '20%',
        },
        tooltip: {
          trigger: 'axis',
          showContent: false,
        },
        legend: {
          top: 20,
        },
        grid: {
          left: 30,
          right: '30%',
          bottom: 20,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        dataset: {
          source: [
            ['product', '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            ['内参', 41.1, 30.4, 65.1, 53.3, 83.8, 98.7, 86.5, 92.1, 85.7, 83.1, 73.4, 55.1],
            ['酒鬼', 86.5, 92.1, 85.7, 83.1, 73.4, 55.1, 24.1, 67.2, 79.5, 86.4, 65.2, 82.5],
            ['湘泉', 24.1, 67.2, 79.5, 86.4, 65.2, 82.5, 41.1, 30.4, 65.1, 53.3, 83.8, 98.7],
            ['洞藏', 55.2, 67.2, 79.5, 86.4, 53.9, 39.1, 86.5, 92.1, 85.7, 83.1, 73.4, 55.1],
          ],
        },
        series: [
          {
            name: '内参', type: 'line', smooth: true, seriesLayoutBy: 'row',
          },
          {
            name: '酒鬼', type: 'line', smooth: true, seriesLayoutBy: 'row',
          },
          {
            name: '湘泉', type: 'line', smooth: true, seriesLayoutBy: 'row',
          },
          {
            name: '洞藏', type: 'line', smooth: true, seriesLayoutBy: 'row',
          },
          {
            type: 'pie',
            id: 'pie',
            radius: '40%',
            center: ['85%', '50%'],
            right: 30,
            label: {
              formatter: '{b}: {@1月} ({d}%)',
            },
            encode: {
              itemName: 'product',
              value: '1月',
              tooltip: '1月',
            },
          },
        ],
      };
      return options;
    },
    // 初始化echarts
    initEchart() {
      const chartDiv = this.$refs.linePie;
      const myChart = echarts.init(chartDiv);
      myChart.setOption(this.getOptions());
      myChart.on('updateAxisPointer', (event) => {
        const xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          const dimension = xAxisInfo.value + 1;
          myChart.setOption({
            series: {
              id: 'pie',
              label: {
                formatter: `{b}: {@[${dimension}]} ({d}%)`,
              },
              encode: {
                value: dimension,
                tooltip: dimension,
              },
            },
          });
        }
      });
    },
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.initEchart();
    });
  },
};
</script>
<style lang="less" scoped>
#linePie{
  width: 100%;
  height: 100%;
}
</style>
